@import '../../styles';

.component-loader-loading {
  width: 100px;
  height: 100px;
  margin: auto;

  @include border($top: true, $left: true, $right: true, $bottom: true, $radius: 0.5em);

  svg {
    height: 100px;
    width: 100px;

    circle {
      fill: rgb(74, 74, 159);
    }

    text {
      font-size: 0.7rem;
    }

    $time: 2;
    $circles: 20;
    @for $i from 0 to $circles {
      $delay: ($time/$circles * $i);

      circle:nth-child(#{$i + 1}) {
        animation: pulse #{$time}s linear infinite;
        animation-delay: -#{$delay}s;
      }
    }
  }

  @keyframes pulse {
    from {
      fill-opacity: 0.9;
    }

    80% {
      fill-opacity: 0;
    }
    to {
      fill-opacity: 0;
    }
  }
}

.component-loader-error {
  @include border($top: true, $left: true, $right: true, $bottom: true);
  background-color: $warning-background-color;
  @include default-padding;
  max-width: $main-grid-middle-size;
  margin: auto;
}
