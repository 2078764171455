@import "src/styles";

.route-upload {
  display: grid;
  grid-template-columns: 1fr $main-grid-middle-size 1fr;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .middle {
    @include middle-bannered-container;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .upload-form {
    label {
      display: grid;
      grid-template-columns: 5em minmax(auto, 20em) auto;
      grid-gap: 0.5em;
      margin: 0.5em 0;
    }

    button {
      @include primary-button;
    }
  }
}
