@import '../../styles';

.document-route-root {
  .annotation-click-context-menu {
    @include context-menu;

    &.hidden {
      display: none;
    }
  }

  .component-tabbed {
    grid-area: canvas;
    overflow: hidden;
  }

  .canvas-wrapper {
    padding: 5px;
    position: relative;

    canvas {
      display: block;
    }
    canvas:not(:first-child) {
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }

  code {
    white-space: pre-wrap;
  }

  .token {
    border: 1px solid black;
    margin: 0.2em;
    padding: 0.3em;
    display: inline-block;
  }
}

