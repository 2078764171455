@import "../styles";

.component-tabbed {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.5em;

  > .head {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    > .name {
      padding: 0em 0.5em;
      @include background-hover-default;

      &.left {
        @include border($bottom: true, $left: true, $top: true, $radius-top-left: 0.2em);
      }
      &.right {
        @include border($bottom: true, $right: true, $top: true, $radius-top-right: 0.2em);
      }
      &.active {
        padding-top: 0.5em;
        @include border($top: true, $left: true, $right: true, $radius-top-left: 0.2em, $radius-top-right: 0.2em);
      }
    }
  }
}