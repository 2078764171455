@import '../../styles';

.stored-search-test {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: var(--space--1);

  &__test-button {
    @include secondary-button;
    width: 100%;
  }

  &__test-output {
    position: relative;
    > * {
      position: absolute;
    }
  }
}
