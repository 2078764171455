@import 'src/styles';

.ripple {
  &__svg-root {
    background-color: white;
    user-select: none;
    font-family: Arial;
    font-size: 16px;
    border: 1px solid black;
    grid-row: 1/2;
    grid-column: 1/3;
  }

  &__node {
    fill: white;
    stroke: black;
    &--new {
      stroke: lightgray;
      &:hover {
        fill: gray;
        cursor: pointer;
      }
    }
  }

  &__add-cross-line {
    stroke: #ccc;
    stroke-width: 10;
  }

  &__edge {
    fill: black;
    stroke: black;
    text {
      stroke: none;
    }
    line {
      marker-end: url(#arrowhead);
    }

    &--new {
      fill: lightgray;
      stroke: lightgray;
      line {
        marker-end: url(#arrowhead-new);
      }
    }
  }

  &__then-line-rect {
    cursor: pointer;
    &--yes {
      fill: lime;
    }
    &--no {
      fill: red;
    }
    &--partial {
      fill: lightgray;
    }
  }

  &__then-line-text {
    stroke: black;
    fill: black;
    cursor: pointer;
  }

  &__node-delete-rect,
  &__node-delete-line {
    cursor: pointer;
    fill: white;
    stroke: black;
    &--new {
      display: none;
    }
  }

  &__condition-text {
    stroke: black;
    fill: black;
  }

  &__container {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-2);
    padding: var(--space-1);
    display: none;

    &--open {
      display: grid;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: var(--zindex-ripple-node-container);
    }
  }

  &__ok-button,
  &__cancel-button {
    @include primary-button();
    text-align: center;
  }
}
