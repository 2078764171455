.icon {
  cursor: pointer;
  user-select: none;

  &--disabled {
    color: gray;
  }

  &--highlight {
    color: yellow;
    background-color: gray;
  }

  &:hover {
    color: blue;
  }
}
