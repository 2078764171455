.document-schema-tree {
  display: grid;
  grid-template-columns: auto 0.7em 2px 0.7em 1fr;
  grid-template-rows: 1fr;
  user-select: none;

  &__horizontal-pipe {
    height: 2px;
    width: 1em;
    background-color: var(--background-300);
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: center;
  }

  &__vertical-pipe {
    background-color: var(--background-300);
    grid-column: 3/4;
    grid-row: 2/3;
  }

  &__name {
    grid-column: 5/6;
    grid-row: 1/2;
    padding: var(--space--3);
    &:hover {
      background-color: var(--background-300);
      cursor: pointer;
    }
  }

  &__children {
    grid-column: 4/6;
    grid-row: 2/3;

    &--hidden {
      display: none;
    }
  }

  &__expander {
    padding: var(--space--3);
    grid-column: 2/5;
    grid-row: 1/2;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: var(--background-300);
    }

    &--empty {
      color: gray;
      cursor: not-allowed;
    }
  }
}
