@import "src/styles";

.route-login {
  display: grid;
  grid-template-columns: 1fr $main-grid-middle-size 1fr;
  grid-template-rows: auto 1fr;
  align-items: start;

  >span {
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: center;
    font-weight: bold;
  }

  form {
    grid-column-start: 2;
    grid-column-end: 3;

    .input-line {
      background-color: rgba(0, 0, 0, 0.05);
      @include default-padding;

      label {
        display: block;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.8);
        padding: 0.1em;
      }

      input {
        width: 100%;
      }

      margin: 1em 0em;
    }

    button {
      @include primary-button;
      width: 100%;
    }
  }
}