@import '../../styles.scss';

.route-synonyms {
  button {
    @include secondary-button();
  }

  .cluster {
    padding: var(--space--2);
    margin: var(--space--5);
    &.collapsed {
      cursor: pointer;
      &:hover {
        background-color: var(--blue-200);
      }
    }

    &.expanded {
      background-color: var(--blue-200);

      input,
      button {
        border: 1px solid var(--background-600);
      }
    }
  }

  .word-row {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: var(--space--1);
    margin: var(--space--1) 0;
  }
}
