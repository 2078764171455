@import '../../styles.scss';

.factcheck-result {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font-weight: normal;
  height: 100%;
  overflow: hidden;

  &__table {
    overflow: scroll;
    height: 100%;
  }

  &__table-cell {
    padding: 0.3em;
    text-align: center;
  }

  &__table-row {
    @include background-hover-even-odd();

    &--error {
      background-color: orange !important;
    }

    &--correct {
      background-color: lime !important;
    }

    &--selected {
      border: 2px solid var(--blue-500);
    }
  }

  &__evidence {
  }

  &__evidence-exhibit {
    margin: 0 0 1em 2em;
  }

  &__table-head {
    padding: 0.3em;
    text-align: center;
  }

  &__single-evidence {
    margin-left: 1em;
  }
}
