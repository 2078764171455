@import "styles";

body,
html,
#root {
  height: 100%;
  width: 100%;
  color: rgb(3, 3, 3);
}

kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(51, 51, 51);
  line-height: 1.4;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  display: inline-block;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #ffffff;
  background-color: rgb(247, 247, 247);
  -moz-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-shadow: 0 1px 0 #fff;
}

.janator-root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;

  .janator-header {
    display: grid;
    grid-template-columns: 1fr var($main-grid-middle-size) 1fr;
    @include border($bottom: true);

    nav {
      grid-column-start: 2;
      grid-column-end: 3;

      > div {
        display: flex;
        flex-direction: row;

        > a {
          font-size: 1.2rem;
          padding: 1em;
          cursor: pointer;

          @include background-hover-default;

          text-decoration: none;
          color: unset;

          &:last-child {
            margin: 0px;
          }
        }
      }
    }
  }
}
