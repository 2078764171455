@import '../../styles.scss';

.hover-help {
  position: relative;

  &__text {
    position: fixed;
    display: none;
    background-color: var(--background-100);
    width: var(--space-10);
    padding: var(--space--1);
    z-index: 100;
    @include border(true, true, true, true);
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.4);
  }
}

.hover-help:hover .hover-help__text {
  display: block;
}
