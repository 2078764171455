@import 'src/styles';

.route-search {
  .result > .progress {
    display: grid;
    grid-template-columns: auto 1fr auto auto auto;
    grid-column-gap: 2em;

    @include border(true, true, true, true);
    padding: $default-padding-size;
    margin: $default-padding-size;

    &.running {
      background-color: $warning-background-color;
    }

    &.done {
      background-color: $success-background-color;
    }
  }

  .result {
    table {
      tr {
        @include background-hover-even-odd;

        td {
          padding: $default-padding-size;
        }
      }
    }

    .pager {
      display: grid;
      grid-template-columns: auto 1fr auto;
      padding: $default-padding-size;
      @include border($bottom: true);

      text-align: center;

      span {
        @include background-hover-default;

        &.current {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      div {
        display: inline-block;
      }
    }
  }
}

.component-loading-overlay {
  position: relative;
  box-sizing: border-box;

  overflow: hidden;

  > .loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;

    &.loading {
      display: block;
    }

    background-color: rgba(255, 255, 255, 0.5);
  }
}
.search-top {
  display: grid;
  grid-template-columns: 0.5em 0.5em auto;
  grid-template-rows: auto auto;

  > .add-plus {
    grid-column: 1/3;
    grid-row: 2/3;

    justify-self: center;
  }

  > .search-scorers {
    grid-column: 2/4;
    grid-row: 1/2;
  }

  .add-plus {
    padding: $default-padding-size;
    @include background-hover-default;
    display: inline-block;
  }

  .inline-remove-minus {
    padding: $default-padding-size;
    @include background-hover-default;
    display: inline-block;
  }

  .scorer-top {
    display: grid;
    grid-template-rows: auto 1fr;

    .scorer-score {
      grid-row: 1/2;

      display: grid;
      grid-template-columns: 1fr auto auto;

      > *:nth-child(1) {
        grid-column: 2/3;
      }
      > *:nth-child(2) {
        grid-column: 3/4;
      }
    }

    .scorer-content {
      width: 100%;
      grid-row: 2/2;
    }

    padding: $default-padding-size;
    padding-left: 0;
    @include border($left: true);
  }

  .matcher {
    display: grid;
    grid-template-columns: 2em 1fr;
    grid-template-rows: auto 1fr;

    .matcher-type-label {
      grid-column: 2/3;
      grid-row: 1/2;

      > div {
        @include background-hover-default;
      }
    }

    .matcher-type-line {
      grid-column: 1/2;
      grid-row: 1/2;
      display: grid;
      grid-template-rows: 1fr 1fr;

      .matcher-line {
        grid-column: 1/2;
        grid-row: 1/2;
        @include border($bottom: true);
      }
    }

    .matcher-content {
      grid-column: 2/3;
      grid-row: 2/3;

      display: grid;
      grid-template-columns: 1em 1em 1fr;
      grid-template-rows: 1fr auto;

      .matcher-content-subs {
        grid-row: 1/2;
        grid-column: 2/4;
      }

      .add-on-grid {
        grid-column: 1/3;
        grid-row: 2/3;
        justify-self: center;
      }

      .matcher-content-line:not(.terminal) {
        @include border($right: true);
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }

    .matcher-input-and-remove-line {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }
}
