@import '../../styles';

.stored-search-overview {
  &__items {
    margin: var(--space--1) 0;
    box-sizing: border-box;
    border: 1px solid var(--background-300);
    overflow: auto;
  }

  &__new-button {
    @include secondary-button;
    width: 100%;
    margin: var(--space--1) 0;
  }

  &__item {
    &:hover {
      background-color: var(--background-300);
    }

    cursor: pointer;
    padding: var(--space--3);

    &:not(:last-child) {
      border-bottom: 1px solid var(--background-300);
    }

    &--active {
      background-color: var(--blue-200);
    }
  }
}
