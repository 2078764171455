.component-context-menu {
  position: fixed;
  width: 0px;
  height: 0px;
  z-index: var(--zindex-context-menu-content);

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    z-index: var(--zindex-context-menu-overlay);
  }

  .sub-menu {
    position: absolute;
    visibility: hidden;
    background-color: #fff;
    border: 1px solid gray;
    max-height: 600px;
    z-index: var(--zindex-context-menu-content);
    box-sizing: border-box;
    top: 0;
    right: 0;
    transform: translate(100%);

    &.no-space-to-the-right {
      transform: translate(-100%);
      right: unset;
      left: 0;
    }

    &.no-space-below {
      top: unset;
      bottom: 0;
    }

    .entry {
      padding: 5px;
      cursor: pointer;
      display: grid;
      position: relative;
      max-width: 400px;
      white-space: nowrap;
      grid-template-columns: 1fr auto;
      grid-column-gap: 1em;

      &:hover {
        background-color: lightgray;
      }
    }

    kbd {
      float: right;
    }
  }

  > .sub-menu {
    visibility: visible;
  }

  .entry:hover > .sub-menu {
    visibility: visible;
  }
}
