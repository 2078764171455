@import 'src/styles';

.settle-schema-selection {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  > div:first-child {
    text-align: center;
    font-weight: var(--fweight-2);
    font-size: var(--fsize-3);
  }
}

.settle-filling-out {
  &__track {
    display: none;

    &--shown {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: var(--space-3);
    }
  }

  &__question-text {
    font-size: var(--fsize-2);
    font-style: italic;
  }

  &__node {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 20em;
  }

  &__buttons {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  &__button {
    user-select: none;
    border: 1px solid black;
    border-right: unset;
    padding: var(--space--3);
    font-variant: small-caps;
    font-size: var(--fsize-2);
    text-align: center;
    display: grid;
    align-items: center;
    justify-items: center;

    &--clickable {
      cursor: pointer;
      &:hover {
        font-weight: var(--fweight-2);
      }
    }

    &--right {
      border-right: 1px solid black;
    }

    &--Yes-clicked {
      background-color: lime;
    }

    &--No-clicked {
      background-color: orange;
    }

    &--Neutral-clicked {
      background-color: gray;
      background-color: lightgray;
    }
  }

  &__completed {
    background-color: hsl(120, 90%, 70%);
    display: none;

    padding: var(--space--1);
    border-radius: 1em;

    &--shown {
      animation-name: appearCompleted;
      animation-duration: 2s;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      justify-items: center;
    }
  }

  &__completed-text {
    font-weight: var(--fweight-2);
    font-size: var(--fsize-3);
    text-align: center;
  }

  &__completed-button {
    border: 2px solid hsl(120, 50%, 40%);
    border-radius: 0.1em;
    padding: var(--space--2);
    font-size: var(--fsize-3);
    text-align: center;
    background-color: hsl(120, 50%, 70%);
    cursor: pointer;
    user-select: none;
    &:hover {
      background-color: hsl(120, 50%, 80%);
    }
  }

  &__results {
    display: none;

    &--shown {
      display: block;
    }

    table {
      tr {
        @include background-hover-even-odd;

        td {
          padding: $default-padding-size;
        }
      }
    }
  }
}
