@import 'src/styles';

.goldstandard-mapping {
  &__add-new {
    @include secondary-button();
  }

  &__table {
    margin: 0 0 var(--space--2) 0;
    width: 100%;

    > tbody > tr > td,
    > thead > tr > td {
      padding: var(--space--2);
    }
  }
}
