@import '../../styles.scss';

.route-analysis {
  &__sub-header {
    font-size: var(--fsize-3);
    font-variant: small-caps;
    text-align: center;
    margin-bottom: var(--space--2);
    margin-top: var(--space-2);

    &--first {
      margin-top: 0;
    }
  }

  &__label {
    font-size: var(--fsize-1);
    font-variant: small-caps;
    margin-bottom: var(--space--2);
    margin-top: var(--space-2);

    &--first {
      margin-top: 0;
    }
  }

  &__selection-table {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: var(--space--1);
    align-items: center;
  }

  &__max-height-result-container {
    max-height: var(--space-7);
    overflow: scroll;
  }

  &__in-ex-picker {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: var(--space--3);
    align-items: center;
  }

  &__in-ex-node-list {
    margin-top: 1em;
    > div {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: var(--space--2);
      align-items: stretch;
      padding: var(--space--5);
      &:hover {
        background-color: var(--blue-200);
      }
    }
  }

  &__secondary-button {
    @include secondary-button();
  }

  &__progress-row {
    display: grid;
    grid-template-columns: 1fr 3em;
    grid-gap: var(--space-2);
    padding: var(--space--2);
  }

  &__mean-row {
    display: grid;
    grid-template-columns: 1fr 3em;
    grid-gap: var(--space-2);
    padding: var(--space--2);
  }

  &__result-row {
    display: grid;
    grid-template-columns: 1fr 3em;
    grid-gap: var(--space-2);
    padding: var(--space--2);

    &:hover {
      background-color: var(--blue-200);
    }

    cursor: pointer;
  }

  &__table-cell {
    padding: 0.3em;
  }

  &__table-row {
    @include background-hover-even-odd();
    cursor: pointer;
  }
}
