.stored-search-meta {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr 1fr;
  grid-gap: var(--space--1);

  &__description,
  &__test-text {
    resize: none;
  }
}
