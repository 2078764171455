@import '../../styles';

.component-toast {
  position: fixed;
  right: 1em;
  top: 1em;
  left: 80%;
  z-index: var(--zindex-modal-dialog);

  > .message {
    @include border(true, true, true, true);
    @include default-padding;

    &.error {
      background-color: $warning-background-color;
    }
    &.success {
      background-color: $success-background-color;
    }

    margin: 1em 0em;

    box-shadow: 0.2em 0.2em 0.2em gray;
  }

  > .dialog {
    @include border(true, true, true, true);
    @include default-padding;

    background-color: rgba(255, 255, 255, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: minmax(0, auto);
    grid-template-rows: minmax(0, auto);
    justify-items: center;
    align-items: center;
    overflow: hidden;

    &.ask-list {
      > .wrapper {
        max-height: 80%;
        overflow: hidden;
        > .answers {
          overflow-y: scroll;
          > * {
            display: block;
            width: 100%;
            border-radius: 0;
            overflow-y: scroll;
          }
        }
      }
    }

    > .wrapper {
      background-color: white;
      box-shadow: 0.2em 0.2em 0.2em gray, 0 0 1px black;
      padding: var(--space--3);
      display: grid;
      height: 100%;
      max-width: 80%;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, auto) minmax(0, auto);
      grid-gap: var(--space-1);
      overflow: hidden;

      > .body {
        font-weight: bold;
        overflow: auto;
      }

      > .answers {
        overflow: auto;
        justify-self: center;
        > * {
          display: inline-block;
          margin-right: var(--space-1);
          @include secondary-button;
        }
      }
    }
  }
}
