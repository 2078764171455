@import "../../styles";

.component-fixed-error {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
  background-image: linear-gradient(90deg, #FFFFFFAA, #000000AA, #000000AA, #FFFFFFAA);

  display: grid;
  justify-items: center;
  align-items: start;
  padding-top: 8em;

  > .content {
    max-width: 50%;
  }
}
