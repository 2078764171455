.document-schemas {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto var(--space-2) auto auto;
  grid-template-areas:
    'pl ph'
    'p p'
    'e e'
    'sl sh'
    's s';
  align-items: center;

  &__label-primary {
    grid-area: pl;
  }
  &__label-secondary {
    grid-area: sl;
  }

  &__select-primary {
    grid-area: p;
  }
  &__select-secondary {
    grid-area: s;
  }
  &__help-primary {
    grid-area: ph;
  }
  &__help-secondary {
    grid-area: sh;
  }
}
