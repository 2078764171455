@import '../../styles';

.component-health-check.failed {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
  background-color: $warning-background-color;
}

.component-health-check.success {
  background-color: $success-background-color;
}
