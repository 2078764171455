$main-grid-middle-size: 830px;
$border-color: rgb(20, 25, 19);
$warning-background-color: rgb(255, 68, 68);
$success-background-color: rgb(109, 255, 57);
$default-padding-size: 0.5em;

@mixin background-hover-default {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@mixin background-hover-even-odd {
  @include background-hover-default;

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03);
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

@mixin border(
  $top: false,
  $right: false,
  $bottom: false,
  $left: false,
  $radius-top-left: 0,
  $radius-top-right: 0,
  $radius-bottom-left: 0,
  $radius-bottom-right: 0,
  $radius: 0,
  $invertColor: false
) {
  $border: 1px solid rgba(0, 0, 0, 0.1);
  @if $invertColor {
    $border: 1px solid rgba(0, 0, 0, 0.9);
  }
  @if $top {
    border-top: $border;
  }
  @if $right {
    border-right: $border;
  }
  @if $bottom {
    border-bottom: $border;
  }
  @if $left {
    border-left: $border;
  }
  @if $radius > 0 {
    border-radius: $radius;
  }
  @if $radius-top-left > 0 {
    border-top-left-radius: $radius-top-left;
  }
  @if $radius-top-right > 0 {
    border-top-right-radius: $radius-top-right;
  }
  @if $radius-bottom-right > 0 {
    border-bottom-right-radius: $radius-bottom-right;
  }
  @if $radius-bottom-left > 0 {
    border-bottom-left-radius: $radius-bottom-left;
  }
}

@mixin primary-button {
  border: 1px solid var(--border-color);
  color: white;
  border-radius: 1em;
  background-color: rgb(30, 160, 240);
  cursor: pointer;
  @include default-padding;
  font-weight: bold;
  &:hover {
    background-color: scale-color(rgb(30, 160, 240), $lightness: -10%);
  }
}

@mixin secondary-button {
  @include background-hover-default;
  @include border(true, true, true, true);
  border-radius: 1em;
  cursor: pointer;
  @include default-padding;
}

@mixin scroll-y {
  height: 100%;
  overflow-y: scroll;
}

@mixin context-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #eeeeee;
  width: 200px;
  max-height: 600px;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;

  &.no-space-to-the-left {
    transform: translate(-100%);
  }

  .entry {
    padding: 5px;
    cursor: pointer;

    &:nth-child(2n) {
      background-color: #fafafa;
    }
    &:hover {
      background-color: #faf154;
    }
  }
}

@mixin default-padding {
  padding: $default-padding-size;
}

@mixin inline-default-padding {
  margin: -$default-padding-size;
  padding: $default-padding-size;
}

@mixin bannered-container {
  @include scroll-y;

  > * {
    margin: 2.5em 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

@mixin middle-bannered-container {
  @include bannered-container;
  grid-column: 2/3;
}

@mixin description-list {
  .label {
    margin-top: 1em;
    font-variant: small-caps;
    font-size: 120%;
    color: gray;
    @include default-padding;
  }

  .data {
    padding-left: 1em;
  }
}

.default-layout {
  display: grid;
  grid-template-columns: 1fr var(--middle-column-size) 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 1005;
  overflow: hidden;
  grid-gap: var(--space-2);

  $p: &;

  &__header {
    grid-column: 1/4;
    grid-row: 1/2;
    padding: var(--space--3) var(--space--1);
    @include border(false, false, true);
  }

  &__left,
  &__middle,
  &__right {
    height: 100%;
    overflow-y: auto;
    padding: 0 var(--space--1);

    &--no-scroll {
      overflow: hidden;
    }

    &--visible-overflow {
      overflow: visible;
    }

    &--2-children {
      display: grid;
      height: 100%;
      grid-template-rows: 1fr 1fr;
      grid-gap: var(--space-1);
    }
  }

  &__left {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  &__middle {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  &__right {
    grid-column: 3/4;
    grid-row: 2/3;
  }

  &__column-element {
    margin-bottom: var(--space-1);
  }
}

.select {
  display: block;
  line-height: 1.3;
  padding: var(--space--3);
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid var(--background-300);
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%238c8c8c%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  &:hover {
    border-color: var(--background-700);
  }
}

.default-input {
  line-height: 1.3;
  padding: var(--space--3);
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid var(--background-300);

  &:focus {
    border-color: var(--blue-300);
  }
}
