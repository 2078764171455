@import 'src/styles';

.matcher-test {
  font-weight: normal;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space--1);
  width: 40em;

  &__button {
    @include primary-button;
  }
}
