:root {
  --middle-column-size: 830px;

  --space--5: 0.1rem;
  --space--4: 0.2rem;
  --space--3: 0.25rem;
  --space--2: 0.5rem;
  --space--1: 0.75rem;
  --space-0: 1rem;
  --space-1: 1.5rem;
  --space-2: 2rem;
  --space-3: 3rem;
  --space-4: 4rem;
  --space-5: 6rem;
  --space-6: 8rem;
  --space-7: 12rem;
  --space-8: 16rem;
  --space-9: 24rem;
  --space-10: 32rem;
  --space-11: 40rem;
  --space-12: 48rem;

  --fsize--2: 0.75rem;
  --fsize--1: 0.875rem;
  --fsize-0: 1rem;
  --fsize-1: 1.125rem;
  --fsize-2: 1.25rem;
  --fsize-3: 1.5rem;
  --fsize-4: 2rem;
  --fsize-5: 3rem;
  --fsize-6: 3.75rem;
  --fsize-7: 4.5rem;

  --fweight--1: 300;
  --fweight-0: 400;
  --fweight-1: 500;
  --fweight-2: 700;

  --background-100: hsl(0, 0%, 100%);
  --background-200: hsl(0, 0%, 95%);
  --background-300: hsl(0, 0%, 90%);
  --background-400: hsl(0, 0%, 80%);
  --background-500: hsl(0, 0%, 65%);
  --background-600: hsl(0, 0%, 55%);
  --background-700: hsl(0, 0%, 50%);
  --background-800: hsl(0, 0%, 45%);
  --background-900: hsl(0, 0%, 40%);

  --blue-100: #f0f4fe;
  --blue-200: #d4def8;
  --blue-300: #95aeed;
  --blue-400: #758ce0;
  --blue-500: #6175de;
  --blue-600: #495dc6;
  --blue-700: #3547a4;
  --blue-800: #243383;
  --blue-900: #1f2c6d;

  --zindex-bannered-fullscreen: 80;
  --zindex-annotation-comment: 90;
  --zindex-ripple-node-container: 95;
  --zindex-context-menu-overlay: 100;
  --zindex-context-menu-content: 110;
  --zindex-modal-dialog: 120;
}
