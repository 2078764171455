@import '../../styles';

.bannered {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  &--scroll {
    height: 100%;
    overflow: hidden;
  }

  &--fullscreen {
    position: fixed;
    z-index: var(--zindex-bannered-fullscreen);
    top: 1em;
    left: 1em;
    right: 1em;
    bottom: 1em;
    background-color: white;
    box-shadow: 0em 0em 1.5em gray;
  }

  &__border-top--success,
  &__label--success,
  &__top-right--success,
  &__body--success {
    background-color: $success-background-color;
  }

  &__border-top--error,
  &__label--error,
  &__top-right--error,
  &__body--error {
    background-color: $warning-background-color;
  }

  &__header {
    display: grid;
    grid-template-columns: 2em auto 1fr auto 2em;
    grid-template-rows: 1fr 1fr;
  }

  &__label {
    grid-column: 2/3;
    grid-row: 1/3;
    display: flex;

    background-color: white;
    @include default-padding();
    @include border($left: true, $right: true, $top: true, $bottom: true);
    align-content: center;
    align-items: center;
  }

  &__top-right {
    grid-column: 4/5;

    grid-row: 1/3;
    background-color: white;
    @include border($left: true, $right: true, $top: true, $bottom: true);

    display: flex;
    &:empty {
      display: none;
    }

    > * {
      @include background-hover-default;
    }
  }

  &__top-right-wrapper {
    display: flex;
    align-items: center;
    padding: 0 0.15em;
  }

  &__top-right-wrapper + &__top-right-wrapper {
    border-left: 1px solid gray;
  }

  &__border-top {
    grid-column: 1/6;
    grid-row: 2/3;
    @include border($left: true, $right: true, $top: true);
  }

  &__body {
    @include border($left: true, $right: true, $bottom: true);
    @include default-padding;
    overflow: auto;
    position: relative;

    &--collapsed {
      max-height: 5em;
      overflow: hidden;
    }

    &--only-top-padding {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    &--scroll {
      height: 100%;
      overflow-y: scroll;
    }
  }

  &__hide-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 0.48) 41%,
      rgba(255, 255, 255, 0.92) 77%,
      rgba(255, 255, 255, 0.93) 78%,
      rgba(255, 255, 255, 1) 100%
    );

    &--shown {
      display: block;
    }
  }
}
