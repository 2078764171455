@import 'src/styles';

.root {
  margin-top: 0.5em;

  &.dragging {
    .adder {
      background-color: yellow !important;
    }
  }

  .node {
    display: grid;
    grid-template-columns: 2em 1fr 1fr;
    grid-template-rows: auto 20px 1fr;
    grid-gap: 0.2em;

    .main {
      grid-row: 1/2;
      grid-column: 2/4;
    }

    .add-sibling {
      grid-row: 2/3;
      grid-column: 2/3;
    }

    .add-child {
      grid-row: 2/3;
      grid-column: 3/4;
    }

    .dragger {
      grid-row: 1/3;
      grid-column: 1/2;
      @include background-hover-default;
      @include border(true, true, true, true);
      user-select: none;
      cursor: move;

      display: grid;
      justify-items: center;
      align-items: center;

      background-color: $success-background-color;

      &.negated {
        background-color: $warning-background-color;
      }
    }

    .subs {
      padding-top: 1em;
      grid-row: 3/4;
      grid-column: 2/4;
    }

    .adder {
      @include background-hover-default;
      @include border(true, true, true, true);

      &.disabled {
        visibility: hidden;
      }
    }

    input {
      width: 100%;

      &.focused {
        background-color: yellow;
      }
    }
  }
}

.spotlight {
  .inner {
    @include description-list;

    button {
      @include secondary-button;
    }
  }
}

.controls {
  button {
    &:not(.primary) {
      @include secondary-button;
    }
    &.primary {
      @include primary-button;
    }
    margin: 0em 0.2em;
  }
}

.schema-main {
  > label {
    font-variant: small-caps;
    font-size: var(--fsize-3);
    display: block;
    margin-top: var(--space-3);
  }

  > label:first-child {
    margin-top: 0;
  }
  > .outcome-nodes > button,
  > button {
    @include secondary-button;
  }
}
