@import '../../styles';

.bills {
  &__unbilled-document,
  &__on-bill {
    padding: var(--space--1);
    cursor: pointer;
    &:hover {
      background-color: var(--blue-100);
    }
  }

  &__bill-in-overview {
    cursor: pointer;
    &:hover {
      background-color: var(--blue-100);
    }
  }

  &__bill {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-1);
    grid-template-areas:
      'name name'
      'description description'
      'list list'
      'save generate';
  }

  &__entries {
    grid-area: list;
  }

  &__name {
    grid-area: name;
    width: 100%;
    border: 1px solid var(--background-300);
  }

  &__description {
    grid-area: description;
    width: 100%;
    border: 1px solid var(--background-300);
  }

  &__save {
    @include primary-button;
    grid-area: save;
  }

  &__generate-pdf {
    @include secondary-button;
    grid-area: generate;
  }

  &__create-button {
    margin-top: var(--space-1);
    @include secondary-button;
  }

  &__add-all {
    margin-top: var(--space-1);
    @include secondary-button;
  }
}
