@import "src/styles";

.component-schema-list {
  .list {
    .entry {
      @include background-hover-even-odd;
      height: 2em;
      display: grid;
      align-items: center;
    }
  }
}
