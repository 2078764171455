@import 'src/styles';

.document-annotations {
  &__annotation {
    display: grid;
    grid-template-columns: 1fr auto auto auto 1em;
    grid-template-areas: 'name group comment state auto';

    &--selected {
      background-color: black;
      color: white;
    }
    &--hovered {
      box-shadow: 0px 0px 4px 4px var(--blue-400);
      background-color: var(--blue-400);
    }
  }

  &__name {
    grid-area: name;
  }

  &__group {
    grid-area: group;
  }

  &__comment {
    grid-area: comment;
  }

  &__state {
    grid-area: state;
    padding: var(--space--4);
    text-align: center;

    &--yes {
      background-color: lime;
    }
    &--partial {
      background-color: yellow;
      color: black;
    }
    &--no {
      background-color: red;
      color: black;
    }
    &--unsubstantiated {
      background-color: pink;
      color: black;
    }
    &--neutral {
      color: black;
      background-color: lightgray;
    }
  }

  &__auto {
    grid-area: auto;
    text-align: center;
  }

  &__header {
    display: grid;
    padding-bottom: var(--space-1);
    grid-template-columns: auto auto 1fr auto;

    align-items: center;
    grid-gap: var(--space--1);

    @include border(false, false, true);

    > span {
      grid-column: 2/3;
    }

    > input {
      grid-column: 1/2;
    }

    > button {
      grid-column: 4/5;
      @include secondary-button;
    }
  }
}

.document-annotation-comment {
  top: calc(50% - var(--space-8) / 2);
  left: calc(50% - var(--space-10) / 2);
  z-index: 100000000;
  position: fixed;
  display: none;
  width: var(--space-10);
  height: var(--space-8);
  background-color: white;
  box-shadow: 0px 0px 12px 12px lightgray;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: var(--space--2);

  &__text {
    color: black;
  }

  &__ok {
    @include primary-button;
    justify-self: center;
    padding: var(--space--2) var(--space-1);
  }

  &--visible {
    display: grid;
  }
}
