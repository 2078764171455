@import "src/styles";

.route-admin {
  display: grid;
  grid-template-columns: 1fr $main-grid-middle-size 1fr;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .middle {
    @include middle-bannered-container;
  }
}